/**
 * The status of an estimate
 */
export const EstimateStatus = {
  Draft: '183a0b0b-3c7b-4598-85aa-c23ec2d2ee8c',
  Sent: 'b9796138-eeac-48a9-a669-b1cbd5eb4b21',
  Payed: '45586da4-0ab2-4b58-a9d9-8e98b5669c2d',
  Refused: 'a8552ad3-583f-4ecd-9f51-d69d1e25dd13',
} as const;

/**
 * The available status of an invoice
 */
export const InvoiceStatus = {
  Draft: '183a0b0b-3c7b-4598-85aa-c23ec2d2ee8c',
  Sent: 'b9796138-eeac-48a9-a669-b1cbd5eb4b21',
  Payed: '45586da4-0ab2-4b58-a9d9-8e98b5669c2d',
} as const;

/**
 * The available status of a credit note
 */
export const CreditNoteStatus = {
  Draft: '8713cd80-a90f-4edf-b6c4-29e563a85574',
  Sent: '3f4d05eb-0d6f-44d2-82d4-b268b84616bf',
  Payed: 'b478982e-0eb6-4c1f-b6ed-39e92e30ebdf',
} as const;
