import { useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalSubmittingState } from 'remix-utils/use-global-navigation-state';
import { $path } from 'safe-routes';

import type { ReadInvoiceDto } from '~/api';
import { ApplicationIcon } from '~/components/application-icons';
import { Form, TextField } from '~/components/form';
import { Button } from '~/components/ui/button';
import { InvoiceStatus } from '~/features/documents/status/statuses';
import { schema } from '~/routes/_auth.invoices.$invoiceId.set-payed';

const ns = 'invoice' satisfies Ns;
export const MarkPayedButton = ({
  className,
  invoiceId,
  invoice,
  redirect,
}: { invoiceId: string; invoice?: Pick<ReadInvoiceDto, 'statusFk'>; redirect?: string; className?: string }) => {
  const state = useGlobalSubmittingState();
  const { t } = useTranslation(ns);

  const id = useId();

  if (!invoice) return null;
  if (invoice.statusFk !== InvoiceStatus.Sent) return null;

  return (
    <Form
      id={id}
      schema={schema}
      className={className}
      action={$path('/invoices/:invoiceId/set-payed', { invoiceId })}
      defaultValue={{
        id: invoiceId,
        invoiceStatusId: InvoiceStatus.Payed,
        redirect,
      }}
    >
      <TextField type='hidden' name='id' />
      <TextField type='hidden' name='invoiceStatusId' />
      <TextField type='hidden' name='redirect' />
      <Button
        icon={<ApplicationIcon icon={state === 'submitting' ? 'loading' : 'success'} />}
        form={id}
        type='submit'
        disabled={state === 'submitting'}
      >
        <Trans ns={ns} i18nKey='button.payed' t={t}>
          Mark as payed
        </Trans>
      </Button>
    </Form>
  );
};
